import React from 'react'
import './featuresSelect.scss'
import Paragraph from "../paragraph/paragraph"
import Title from "../title/title"
import Button from "../button/button"

const FeaturesSelect = () => {

    const switcher = (event) => {
        document.querySelectorAll('.features-select').forEach((item) => {item.classList.remove('active')})
        event.currentTarget.classList.add('active')
    }

    return(
        <div>
            <div className='text-left features-select active' onClick={switcher}>
                <Title title="Inventory Syncing"/>
                <Paragraph text="Seamlessly upload items to the Marketplace, while keeping inventory levels and pricing in sync" style='mb-3'/>
                <Button href='/features/product-publishing' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="Order Routing"/>
                <Paragraph text="Automatically split an order into the different fulfillment flows between Vendors, In-House or 3PL" style='mb-3'/>
                <Button href='/features/order-routing' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="Shipping Workflows"/>
                <Paragraph text="Define a fully flexible network of Carriers, customer shipping rates, and shipping cost allocation" style='mb-3'/>
                <Button href='/features/vendor-shipping-rules' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="Payments Automation"/>
                <Paragraph text="Automatically calculate commission rates for all items sold and import cost prices in batches from vendors" style='mb-3'/>
                <Button href='/features/automated-vendor-payments-and-payment-reports' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="Returns Management"/>
                <Paragraph text="Offer your customers returns shipping labels and track them using Onport’s automated system" style='mb-3'/>
                <Button href='/features/returns-management' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="Vendor portal"/>
                <Paragraph text="Empower your vendors to manage their own orders, enter tracking information and download their own custom packing slips" style='mb-3'/>
                <Button href='/features/vendor-portal' text='Learn more' style='inline-text'/>
            </div>
            <div className='text-left features-select' onClick={switcher}>
                <Title title="API Custom Workflows"/>
                <Paragraph text="In the case your business needs to build a customized workflow that is not yet built into Onport, our APIwith +3,000 endpoints is readily available " style='mb-3'/>
                <Button href='/features/api-custom-workflows' text='Learn more' style='inline-text'/>
            </div>
        </div>
    )
}

export default FeaturesSelect